<template>
  <div class="addPageVehicle">
    <div class="facilityBox" style="overflow-y: hidden">
      <el-breadcrumb separator="/" style="margin: 10px 0">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>认证车辆</el-breadcrumb-item>
        <el-breadcrumb-item>车辆信息</el-breadcrumb-item>
      </el-breadcrumb>
      <div
        class="topWrap"
        v-if="vehicleInfo.Status == 2 || vehicleInfo.Status == 210"
      >
        <div style="width: 300px; height: 200px">{{ vehicleInfo.Remark }}</div>
        <!-- <el-button
          type="danger"
          size="medium"
          style="height: 34px"
          @click="causeModel = true"
        >
          驳回原因
        </el-button> -->
      </div>

      <!-- 主体区域 -->
      <div style="display: flex; margin-top: 10px">
        <!-- 左侧 -->
        <div
          class="left"
          style="
            width: 316px;
            height: calc(100vh - 200px);
            overflow-y: auto;
            padding-right: 16px;
          "
        >
          <!-- 证照信息 -->
          <div class="title">证照信息</div>
          <!-- 可编辑 -->
          <div
            class="content"
            v-if="vehicleInfo.Status == 0 || vehicleInfo.Status == 210"
          >
            <div class="upBox">
              <UploadImg
                ref="vehicleFace"
                :loading="vehicleImg.vehicleFace.loading"
                :uploaded="vehicleImg.vehicleFace.uploaded"
                :picUrl="vehicleImg.vehicleFace.picUrl"
                @update:file="updateFile"
                :fileType="'Vehicle'"
                @change="upVehicleFace(vehicleImg.vehicleFace)"
                @deleteImg="deleteImg(vehicleImg.vehicleFace)"
                :isShowNoOcr="true"
                @changeIsOcrImg="changeIsOcrImg"
              >
              </UploadImg>
              <div class="upText">
                <span class="required">*</span>
                车辆行驶证正页(正面)
                <i
                  @click="imgCase(14)"
                  class="el-icon-question"
                  style="cursor: pointer; color: rgb(230, 160, 62)"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="vehiclePageOn"
                :loading="vehicleImg.vehiclePageOn.loading"
                :uploaded="vehicleImg.vehiclePageOn.uploaded"
                :picUrl="vehicleImg.vehiclePageOn.picUrl"
                @update:file="updateFile"
                :fileType="'Vehicle'"
                @change="upVehicleBack(vehicleImg.vehiclePageOn)"
                @deleteImg="deleteImg(vehicleImg.vehiclePageOn)"
                :isShowNoOcr="true"
                @changeIsOcrImg="changeIsOcrImg"
              >
              </UploadImg>
              <div class="upText">
                <span class="required">*</span>
                车辆行驶证副页(正面)
                <i
                  @click="imgCase(15)"
                  class="el-icon-question"
                  style="cursor: pointer; color: rgb(230, 160, 62)"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="vehicleRTP"
                :loading="vehicleImg.vehicleRTP.loading"
                :uploaded="vehicleImg.vehicleRTP.uploaded"
                :picUrl="vehicleImg.vehicleRTP.picUrl"
                @update:file="updateFile"
                :fileType="'Vehicle'"
                @change="upvehicleRTP(vehicleImg.vehicleRTP)"
                @deleteImg="deleteImg(vehicleImg.vehicleRTP)"
              >
              </UploadImg>
              <div class="upText">
                道路运输证
                <i
                  @click="imgCase(17)"
                  class="el-icon-question"
                  style="cursor: pointer; color: rgb(230, 160, 62)"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="vehicleBack"
                :loading="vehicleImg.vehicleBack.loading"
                :uploaded="vehicleImg.vehicleBack.uploaded"
                :picUrl="vehicleImg.vehicleBack.picUrl"
                @update:file="updateFile"
                :fileType="'Vehicle'"
                @change="upVehiclePageOn(vehicleImg.vehicleBack)"
                @deleteImg="deleteImg(vehicleImg.vehicleBack)"
              >
              </UploadImg>
              <div class="upText">
                车辆行驶证副页(反面)
                <i
                  @click="imgCase(16)"
                  class="el-icon-question"
                  style="cursor: pointer; color: rgb(230, 160, 62)"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="elsePapers"
                :loading="vehicleImg.elsePapers.loading"
                :uploaded="vehicleImg.elsePapers.uploaded"
                :picUrl="vehicleImg.elsePapers.picUrl"
                @update:file="updateFile"
                :fileType="'Vehicle'"
                @change="upelsePapers(vehicleImg.elsePapers)"
                @deleteImg="deleteImg(vehicleImg.elsePapers)"
              >
              </UploadImg>
              <div class="upText">
                附加证照
                <!-- <i @click="imgCase(18)" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                案例
              </i> -->
              </div>
            </div>
          </div>
          <!-- 不可编辑 -->
          <div class="content" v-else>
            <div class="upBox">
              <el-image
                class="img"
                :src="vehicleImg.vehicleFace.picUrl"
                :preview-src-list="srcList"
              >
              </el-image>
              <div class="upText">车辆行驶证正页(正面)</div>
            </div>
            <div class="upBox">
              <el-image
                class="img"
                :src="vehicleImg.vehiclePageOn.picUrl"
                :preview-src-list="srcList"
              >
              </el-image>
              <div class="upText">车辆行驶证副页(正面)</div>
            </div>
            <div class="upBox" v-if="vehicleImg.vehicleBack.picUrl">
              <el-image
                class="img"
                :src="vehicleImg.vehicleBack.picUrl"
                :preview-src-list="srcList"
              >
              </el-image>
              <div class="upText">车辆行驶证副页(反面)</div>
            </div>
            <div class="upBox" v-if="vehicleImg.vehicleRTP.picUrl">
              <el-image
                class="img"
                :src="vehicleImg.vehicleRTP.picUrl"
                :preview-src-list="srcList"
              >
              </el-image>
              <div class="upText">道路运输证</div>
            </div>
            <div class="upBox" v-if="vehicleImg.elsePapers.picUrl">
              <el-image
                class="img"
                :src="vehicleImg.elsePapers.picUrl"
                :preview-src-list="srcList"
              >
              </el-image>
              <div class="upText">附加证照</div>
            </div>
          </div>
        </div>
        <!-- 右侧 -->

        <div
          class="right"
          style="
            flex: 1;
            height: calc(100vh - 200px);
            overflow-y: auto;
            margin: 0 20px;
          "
        >
          <span style="margin: 0px 8px 0px 20px">所属企业：</span>
          <el-input
            readonly
            v-model="vehicleInfo.EnterpriseFullName"
            placeholder="车辆所属企业"
            style="width: 200px; margin-right: 20px"
          ></el-input>
          <span style="margin: 0px 8px 0px 20px">审核员：</span>
          <el-input
            v-model="OperatorName"
            placeholder="请输入审核员姓名"
            @blur="getOperatorName"
            style="width: 200px"
          >
          </el-input>
          <div class="title">基本信息</div>
          <el-form
            class="vehicleInfo"
            :model="vehicleInfo"
            ref="vehicleInfo"
            :rules="ruleVehicle"
            label-width="140px"
            inline
            :disabled="
              vehicleInfo.Status == 0 || vehicleInfo.Status == 210
                ? false
                : true
            "
          >
            <el-form-item label="车牌号" prop="VehicleCarNumber">
              <el-input
                v-model="vehicleInfo.VehicleCarNumber"
                placeholder="请输入车牌号"
              ></el-input>
            </el-form-item>
            <el-form-item label="车牌颜色" prop="CarNumberColor">
              <el-select
                v-model="vehicleInfo.CarNumberColor"
                clearable
                placeholder="请选择车牌颜色"
              >
                <el-option
                  v-for="item in vehicleColorList"
                  :key="item.Name"
                  :label="item.Name"
                  :value="item.Code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车辆类型" prop="VehicleTypeName">
            <el-select
              v-model="vehicleInfo.VehicleTypeName"
              ref="produceRef"
              clearable
              filterable
              placeholder="请选择车辆类型"
              @blur="productSelect"
              @clear="productClear"
              @change="productChange"
              allow-create
            >
              <el-option
                v-for="item in produces"
                :key="item.Id"
                :label="item.VehicleTypeName"
                :value="item.VehicleTypeName"
              />
            </el-select>
          </el-form-item>
           
            <el-form-item label="所有人" prop="VehicleMaster">
              <el-input
                v-model="vehicleInfo.VehicleMaster"
                placeholder="请输入所有人"
              ></el-input>
            </el-form-item>
            <el-form-item label="载重(吨)" prop="VehicleLoad">
              <el-input
                v-model="vehicleInfo.VehicleLoad"
                onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                placeholder="请输入载重(吨)"
              ></el-input>
            </el-form-item>
            <el-form-item label="自重(吨)" prop="VehicleTotalWeight">
              <el-input
                v-model="vehicleInfo.VehicleTotalWeight"
                onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                placeholder="请输入自重(吨)"
              ></el-input>
            </el-form-item>
            <el-form-item label="总重(吨)" prop="VehicleLoadWeight">
              <el-input
                v-model="vehicleInfo.VehicleLoadWeight"
                onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                placeholder="请输入总质量"
              ></el-input>
            </el-form-item>
            <el-form-item label="行驶证有效期" prop="VehicleLicenseValidDate">
              <el-date-picker
                v-model="vehicleInfo.VehicleLicenseValidDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择行驶证有效期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="车长(米)" prop="VehicleLength">
              <el-input
                v-model="vehicleInfo.VehicleLength"
                onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                placeholder="请输入车身长度"
              ></el-input>
            </el-form-item>
            <el-form-item label="车宽(米)" prop="VehicleWidth">
              <el-input
                v-model="vehicleInfo.VehicleWidth"
                onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                placeholder="请输入车身宽度"
              ></el-input>
            </el-form-item>
            <el-form-item label="车高(米)" prop="VehicleHeight">
              <el-input
                v-model="vehicleInfo.VehicleHeight"
                onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                placeholder="请输入车高(米)"
              ></el-input>
            </el-form-item>
            <el-form-item label="能源类型" prop="VehiclePowerType">
              <el-select
                v-model="vehicleInfo.VehiclePowerType"
                clearable
                placeholder="能源类型"
              >
                <el-option
                  v-for="item in powerList"
                  :key="item.Name"
                  :label="item.Name"
                  :value="item.Code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="使用性质" prop="VehicleUsage">
              <el-input
                v-model="vehicleInfo.VehicleUsage"
                placeholder="请输入使用性质"
              ></el-input>
            </el-form-item>
            <el-form-item label="品牌型号" prop="BrandModelNumber">
              <el-input
                v-model="vehicleInfo.BrandModelNumber"
                placeholder="请输入品牌型号"
              ></el-input>
            </el-form-item>
            <el-form-item label="发动机号" prop="PowerNumber">
              <el-input
                v-model="vehicleInfo.PowerNumber"
                placeholder="请输入发动机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="车辆识别代码" prop="VehicleVIN">
              <el-input
                v-model="vehicleInfo.VehicleVIN"
                placeholder="请输入车辆识别代码"
              ></el-input>
            </el-form-item>
            <el-form-item label="行驶证注册日期" prop="VehicleLicenseRegDate">
              <el-date-picker
                v-model="vehicleInfo.VehicleLicenseRegDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择行驶证注册日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="行驶证发证日期" prop="VehicleLicenseAwardDate">
              <el-date-picker
                v-model="vehicleInfo.VehicleLicenseAwardDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择行驶证发证日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="道路运输证号" prop="vehicleRTP">
              <el-input
                v-model="vehicleInfo.VehicleRTP"
                placeholder="请输入道路运输证号"
              ></el-input>
            </el-form-item>
            <el-form-item label="核载人数" prop="VehicleLoadPersons">
              <el-input
                onkeyup="value=value.replace(/[^\d]/g,'')"
                v-model="vehicleInfo.VehicleLoadPersons"
                placeholder="请输入核载人数"
              ></el-input>
            </el-form-item>
            <el-form-item label="购车日期" prop="VehicleBuyDate">
              <el-date-picker
                v-model="vehicleInfo.VehicleBuyDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择购车日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="年审日期" prop="InspectAnnuallyDate">
              <el-date-picker
                v-model="vehicleInfo.InspectAnnuallyDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择年审日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="商业险到期日期" prop="BInsuranceDate">
              <el-date-picker
                v-model="vehicleInfo.BInsuranceDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择商业险到期日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="交强险到期日期" prop="CInsuranceDate">
              <el-date-picker
                v-model="vehicleInfo.CInsuranceDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择交强险到期日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="轴数" prop="VehicleAxlesNumber">
              <el-input
                v-model="vehicleInfo.VehicleAxlesNumber"
                placeholder="请输入轴数"
                maxlength="4"
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-input>
            </el-form-item>
            <el-form-item label="发证机关" prop="VehicleLicesenAuthority">
              <el-input
                v-model="vehicleInfo.VehicleLicesenAuthority"
                placeholder="请输入发证机关"
              ></el-input>
            </el-form-item>
            <el-form-item label="归属类型" prop="VehicleAttribution">
              <el-select
                v-model="vehicleInfo.VehicleAttribution"
                clearable
                placeholder="归属类型"
              >
                <el-option
                  v-for="item in affiliation"
                  :key="item.Name"
                  :label="item.Name"
                  :value="item.Code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="head">
        <el-button size="medium" icon="el-icon-arrow-left" @click="routerBack()"
          >返回</el-button
        >
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-refresh-left"
          v-if="vehicleInfo.Status == 0 || vehicleInfo.Status == 210"
          @click="undoModify()"
          >撤销修改</el-button
        >
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-check"
          v-if="vehicleInfo.Status == 0 || vehicleInfo.Status == 210"
          @click="upVehicleData('save', 'vehicleInfo')"
          :loading="flag.save"
          >保存</el-button
        >
        <el-button
          type="danger"
          @click="rejectModel = true"
          v-if="vehicleInfo.Status == 0 || vehicleInfo.Status == 210"
          icon="el-icon-close"
        >
          拒绝通过并下一个
        </el-button>
        <el-button
          type="success"
          :disabled="isFinish"
          @click="upVehicleData('approved', 'vehicleInfo')"
          v-if="vehicleInfo.Status == 0 || vehicleInfo.Status == 210"
          :icon="succIcon"
        >
          认证通过并下一个</el-button
        >

        
        <el-button
        v-if="vehicleInfo.Status == 0 || vehicleInfo.Status == 210"
          type="warning" 
          @click="toNext"
          icon="el-icon-star-off"
        >
        查看下一个</el-button
        >
 
        <el-button
          type="primary"
          @click="getCarNewLocation"
          icon="el-icon-s-promotion"
          v-if="vehicleInfo.Status != 0 || vehicleInfo.Status != 210"
          >当前位置</el-button
        >
        <!-- <el-button
          type="danger"
          @click="BatchBackDraft"
          v-if="vehicleInfo.Status == 2"
          icon="el-icon-close"
        >
          退回草稿
        </el-button> -->
      </div>
    </div>
    <!-- 驳回弹窗 -->
    <el-dialog
      class="rejectDialog"
      title="驳回意见"
      :visible.sync="rejectModel"
      width="30%"
      center
    >
      <span>驳回 {{ vehicleInfo.VehicleCarNumber }}(车辆) 的提交申请：</span>
      <div style="margin: 20px 0 0 0">
        <el-input
          type="textarea"
          maxlength="100"
          show-word-limit
          placeholder="请输入驳回处理意见，限制100字。"
          v-model.trim="rejectValue"
        ></el-input>
        <el-checkbox-group
          v-model="checkFastReplyList"
          style="margin-top: 16px"
        >
          <el-checkbox
            :label="item.Name"
            v-for="item in fastReplyList"
            :key="item.Code"
            style="padding: 5px 0"
          >
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectModel = false">取 消</el-button>
        <el-button type="primary" @click="refuseClick(2)">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看账户案例 -->
    <el-dialog
      class="addDialog"
      :visible.sync="flag.isCase"
      width="840px"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title"> 查看案例 </span>
      <div v-for="(item, index) in caselist" :key="index">
        <img style="width: 800px" :src="item.SampleURL" />
        <div
          style="width: 100; text-align: center; font-size: 16px; color: #000"
        >
          {{ item.Name }}
        </div>
      </div>
    </el-dialog>
    <!-- 驳回弹窗 -->
    <el-dialog :visible.sync="causeModel" width="30%" center>
      <span slot="title" class="dialog-title"> 驳回原因 </span>
      <div
        style="
          border: 1px solid rgb(96, 98, 102);
          border-radius: 5px;
          padding: 8px;
        "
        v-html="splitRejectReason(vehicleInfo.Remark)"
      ></div>
    </el-dialog>
    <!-- 地图组件dialog -->
    <el-dialog
      width="800px"
      :visible.sync="flag.showMap"
      class="deep_dialog"
      append-to-body
    >
      <span slot="title">查看地图</span>
      <!-- 地图组件 -->
      <TXmap
        style="height: 600px"
        v-if="flag.showMap"
        ref="map"
        :mapdialog="flag.showMap"
        :list="[]"
        maptype="position"
        :posinfo="posinfo"
      >
      </TXmap>
    </el-dialog>
  </div>
</template>

<script>
import UploadImg from "@/components/commonCmpt/cropperImage";
import TXmap from "@/components/commonCmpt/TXmap";
import { getDataDict, GetSampleInfo,upLoadImgByUrl } from "@/api/common/common";
import {
  uploadVehicleFace,
  uploadVehicleBack,
  uploadVehiclePageOn,
  uploadVehicleRTP,
} from "@/api/transport/vehicleManage/verify";
import { GetAuthorityByCarNumber } from "@/api/transport/vehicleManage/index";
import { uploadOherCertificate } from "@/api/goodsOwner/goodsOwnerManage/authInfo";
import {
  VehicleDeatils,
  UpdateVehicleInfo,
  TCCarAutoCheckList,
} from "@/api/transport/vehicleManage/index";
 
import {
  CertificationVehicle,
  GetCarNewLocation,
  BatchDraftCar,
} from "@/api/auditInfo/certified/index";
import verify from "@/utils/verify";
export default {
  data() {
    return {
      produces: [], //车辆类型
      // 状态集合
      flag: {
        isCase: false,
        save: false, //提交保存中
        showMap: false,
      },
      posinfo: {},
      // 案例列表
      caselist: [],
      // 图片放大预览
      srcList: [],
      // 驳回弹窗
      rejectModel: false,
      // 驳回意见
      rejectValue: "",
      // 通过Icon
      succIcon: "el-icon-check",
      // 当前车辆ID
      vehicleID: "",
      // 基本信息
      vehicleInfo: {
        VehicleCarNumber: "",
        CarNumberColor: "",
        VehicleType: "",
        VehicleLoad: "",
        VehicleLength: "",
        VehicleWidth: "",
        vehicleLicenseValidDate: "",
        vehicleRTP: "",
        VehicleLoadWeight: "",
        VehicleMaster: "",
        VehiclePowerType: "",
        VehicleTotalWeight: "",
        VehicleHeight: "",
        VehicleUsage: "",
        BrandModelNumber: "",
        PowerNumber: "",
        VehicleVIN: "",
        VehicleLicenseRegDate: "",
        VehicleLicenseAwardDate: "",
        VehicleLicesenAuthority: "",
        VehicleLoadPersons: "",
        VehicleBuyDate: "",
        InspectAnnuallyDate: "",
        BInsuranceDate: "",
        CInsuranceDate: "",
        VehicleAxlesNumber: "",
        EnterpriseFullName: "", // 所属企业
      },
      // 证件照片
      vehicleImg: {
        //车辆行驶证(正页)
        vehicleFace: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //车辆行驶证副页(正面)
        vehicleBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //车辆行驶证副页(反面)
        vehiclePageOn: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //道路运输经营许可证
        vehicleRTP: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //其他证件
        elsePapers: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      },
      // 表单校验
      ruleVehicle: {
        VehicleCarNumber: [
          { required: true, message: "请输入车牌号", trigger: "change" },
        ],
        CarNumberColor: [
          { required: true, message: "请选择车牌颜色", trigger: "change" },
        ],
        VehicleLoad: [
          { required: true, message: "请输入载重", trigger: "change" },
        ],
        VehicleLength: [
          { required: true, message: "请输入车长", trigger: "change" },
        ],
        // VehicleLicenseValidDate: [{ required: true, message: '请选择行驶证有效期', trigger: 'change' }],
        VehicleTypeName: [
          { required: true, message: "请输入车辆类型", trigger: "change" },
        ],
        VehicleMaster: [
          { required: true, message: "请输入所有人", trigger: "change" },
        ],
        VehiclePowerType: [
          { required: true, message: "请输选择能源类型", trigger: "change" },
        ],
        VehicleLoadWeight: [
          { required: true, message: "请输入总量", trigger: "change" },
        ],
        VehicleLicesenAuthority: [
          { required: true, message: "请输入发证机关", trigger: "change" },
        ],
      },
      //归属类型
      affiliation: [],
      //车辆颜色
      vehicleColorList: [],
      //能源类型
      powerList: [],
      // 是否禁用
      isCompile: false,
      // 当前组件正在操作的文件
      currentFile: "",
      // Tab状态
      urlType: "",
      // 归属ID
      AscriptionUserID: "",
      // 当前页数
      pageIndex: 1,
      // 驳回弹窗
      causeModel: false,
      // 需返回的参数
      EnterpriseFullName: "",
      LegalPersonlPhone: "",
      SubmitDatetimeStart: "",
      SubmitDatetimeEnd: "",
      CarNumber: "",
      auditStatus: "",
      isFinish: false, // 请求认证通过接口是否完成
      OperatorName: "", // 操作人
      fastReplyList: [], //快捷回复列表
      checkFastReplyList: [], //选中的快捷回复
      isOcrImg:true,//图片是否ocr识别
    };
  },
  methods: {
    setTCCarAutoCheckList() {
      TCCarAutoCheckList().then((res) => {
        this.produces = res.data;
        console.log(this.produces);
      })
    },
    productChange(e) {
      var entity=this.produces.filter(it=>it.VehicleTypeName==e);
      if(entity.length)
      {  
        let element=entity[0];
        this.vehicleInfo.VehiclePowerType=element.PowerCode  //能源类型
        this.vehicleInfo.CarNumberColor=element.CarNumberColorCode    //车牌颜色
        this. vehicleInfo.VehicleLoadWeight=element.TotalWeightMax   //总重(吨)
        this. vehicleInfo.VehicleLoad=element.TotalLoadWeightMax   //载重(吨)
        this. vehicleInfo.VehicleLength=element.VehicleLenghtMax  //车长(米
      }

    },
    productClear()
    {
        this.vehicleInfo.VehiclePowerType=""  //能源类型
        this.vehicleInfo.CarNumberColor=""    //车牌颜色
        this. vehicleInfo.VehicleLoadWeight=""    //总重(吨)
        this. vehicleInfo.VehicleLoad=""   //载重(吨)
        this. vehicleInfo.VehicleLength=""   //车长(米
    },
    productSelect(e) {
      let value = e.target.value; // 输入框值
      if (value) {
        // 只有输入才有这个值，下拉框选择的话 这个值为空
        this.vehicleInfo.VehicleTypeName = value;
      } 
    },
    changeIsOcrImg(val){
      this.isOcrImg=val
    },
    // 存储操作人
    getOperatorName() {
      localStorage.setItem("carOperatorName", this.OperatorName);
    },
    // 车辆最新轨迹查询
    getCarNewLocation() {
      const loading = this.$loading({
        lock: true,
        text: "位置请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      GetCarNewLocation({ carNumber: this.vehicleInfo.VehicleCarNumber })
        .then((res) => {
          this.posinfo = res.data || {};
          this.posinfo.carNumber = this.vehicleInfo.VehicleCarNumber;
          this.flag.showMap = true;
        })
        .finally(() => {
          loading.close();
        });
    },
    // 查看案例
    imgCase(SampleType) {
      this.caselist = [];
      GetSampleInfo({ SampleType }).then((res) => {
        this.caselist = res.data;
        this.flag.isCase = true;
      });
    },
    undoModify() {
      // 获取车辆信息
      this.getVehicleDeatils(this.vehicleID);
    },
    // 返回上一页
    routerBack() {
      this.$router.push({
        path: "/auditInfo/certifiedCar/index",
        query: {
          urlType: this.urlType,
          pageIndex: this.pageIndex,
          EnterpriseFullName: this.EnterpriseFullName,
          pagesize: this.$route.query.pagesize,
          LegalPersonlPhone: this.LegalPersonlPhone,
          SubmitDatetimeStart: this.SubmitDatetimeStart,
          SubmitDatetimeEnd: this.SubmitDatetimeEnd,
          VerifyDatetimeStart: this.$route.query.VerifyDatetimeStart,
          VerifyDatetimeEnd: this.$route.query.VerifyDatetimeEnd,
          CreateDatetimeStart: this.$route.query.CreateDatetimeStart,
          CreateDatetimeEnd: this.$route.query.CreateDatetimeEnd,
          CarNumber: this.CarNumber,
          auditStatus: this.auditStatus,
          Remark:this.$route.query.Remark,
        },
      });
    },
    // 是否通过审核
    refuseClick(num, formName) {
      let data = {
        VehicleID: this.vehicleID,
        Status: num,
        OperatorName: this.OperatorName,
      };
      let text = "";
      if (num == 2) {
        data.Remark =
          this.rejectValue +
          (this.rejectValue ? ";" : "") +
          this.checkFastReplyList.join(";");
        text = "确定驳回该车辆的审核信息吗?";
        this.$confirm(text, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            CertificationVehicle(data)
              .then((res) => {
                this.rejectModel = false;
                this.toNext();
                this.checkFastReplyList = [];
                // setTimeout(() => {
                //   this.routerBack()
                // }, 1000);
              })
              .finally(() => {
                this.succIcon = "el-icon-check";
              });
          })
          .catch(() => {
            this.succIcon = "el-icon-check";
          });
      }
    },
    // 上传车辆信息
    upVehicleData(type, formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            baseinfo: this.vehicleInfo,
            confirmInfo: {
              vehicleID: this.vehicleID ? this.vehicleID : "",
              vehicleLicenseFrontPageURL: this.vehicleImg.vehicleFace.picUrl,
              vehicleLicenseSubPageOnURL: this.vehicleImg.vehiclePageOn.picUrl,
              vehicleLicenseSubPageBackURL: this.vehicleImg.vehicleBack.picUrl,
              vehicleRTPUTL: this.vehicleImg.vehicleRTP.picUrl,
              AdditionalLicenseURL: this.vehicleImg.elsePapers.picUrl,
            },
            type: 0,
            AscriptionUserID: this.AscriptionUserID,
          };
          if (params.confirmInfo.vehicleLicenseFrontPageURL == "") {
            this.$message.error("请上传行驶证正页");
            return;
          }
          if (params.confirmInfo.vehicleLicenseSubPageOnURL == "") {
            this.$message.error("请上传行驶证副页（正面）");
            return;
          }
          if (!verify._isCarNum(params.baseinfo.VehicleCarNumber)) {
            this.$message({
              message: "车牌输入有误，请重新输入",
              type: "warning",
            });
            return;
          }
          if (params.baseinfo.VehicleLoad == "") {
            this.$message({
              message: "请重新输入载重",
              type: "warning",
            });
            return;
          }
          if (params.baseinfo.VehicleLength == "") {
            this.$message({
              message: "请重新输入车长",
              type: "warning",
            });
            return;
          }
          this.flag.save = true;
          if (type == "approved") {
            this.$confirm("是否确定认证该车辆?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.isFinish = true;
                UpdateVehicleInfo(params)
                  .then((res) => {
                    let data = {
                      VehicleID: this.vehicleID,
                      Status: 1,
                      OperatorName: this.OperatorName,
                    };
                    CertificationVehicle(data)
                      .then((res) => {
                        this.toNext();
                      })
                      .finally(() => {
                        this.isFinish = false;
                        this.succIcon = "el-icon-check";
                      })
                      .catch(() => {
                        this.isFinish = false;
                      });
                  })
                  .finally(() => {
                    this.flag.save = false;
                  })
                  .catch(() => {
                    this.isFinish = false;
                  });
              })
              .catch(() => {
                this.succIcon = "el-icon-check";
              });
          } else {
            UpdateVehicleInfo(params)
              .then((res) => {
                // 获取车辆信息
                this.getVehicleDeatils(this.vehicleID);
              })
              .finally(() => {
                this.flag.save = false;
              });
          }
        }
      });
    },
    // 下一条
    toNext() {
      this.isFinish = false;
      let allList = localStorage.getItem("carList")
        ? localStorage.getItem("carList")
        : [];
      if (allList && JSON.parse(allList).length) {
        allList = JSON.parse(allList);
        for (let i = 0; i < allList.length; i++) {
          let obj = allList[i];
          if (obj.VehicleID == this.vehicleID) {
            if (allList[i + 1]) {
              this.vehicleID = allList[i + 1].VehicleID;
              this.AscriptionUserID = allList[i + 1].AscriptionUserID;
              this.getVehicleDeatils(this.vehicleID);
              setTimeout(() => {
                if (
                  this.vehicleInfo.VehicleCarNumber &&
                  !this.vehicleInfo.VehicleLicesenAuthority
                ) {
                  GetAuthorityByCarNumber({
                    carnumber: this.vehicleInfo.VehicleCarNumber,
                  }).then((res) => {
                    this.vehicleInfo.VehicleLicesenAuthority = res.data;
                  });
                }
              }, 2000);
              this.rejectValue = "";
              return true;
            } else {
              this.$message.warning("暂无下一条");
              setTimeout(() => {
                this.routerBack();
              }, 1000);
            }
          }
        }
      }
    },
    // 上传附件
    upelsePapers(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadOherCertificate(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.ConsInfo;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.elsePapers.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },

     //上传图片
     uploadImg(field){
      var reader = new FileReader();
      reader.readAsDataURL(this.currentFile);
      reader.onload = () => {
        upLoadImgByUrl({ FileBase64: reader.result, FileType: "Driver" })
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl= res.data;
            console.log(field.picUrl,"field.picUrl");
          })
          .catch(() => {})
          .finally(() => {
            field.loading = false;
            field.uploaded = false;
          });
      };
    },

    // 上传车辆道路运输证照片
    upvehicleRTP(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadVehicleRTP(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          let obj = res.data ? res.data : {};
          field.picUrl = obj.ImgUrl;
          this.vehicleInfo.VehicleRTP = obj.Detail
            ? obj.Detail.SerialNumber
            : "";
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.vehicleRTP.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    // 上传行驶证副页（反面）
    upVehiclePageOn(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadVehiclePageOn(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.vehicles;
        })
        .catch(() => {
          // this.deleteImg(field)
        })
        .finally(() => {
          this.$refs.vehicleBack.dialog();
          field.loading = false;
          field.uploaded = false;
        });
    },
    // 上传行驶证副页（正面）
    upVehicleBack(field) {
      field.loading = true;
      if(this.isOcrImg){
        let formData = new FormData();
        formData.append("file", this.currentFile);
        uploadVehicleBack(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.vehicles.vehicleBackUrl;
            this.vehicleInfo.VehicleLength = res.vehicles.overall_dimension
              ? Number(res.vehicles.overall_dimension.split("X")[0]) / 1000
              : "";
            this.vehicleInfo.VehicleWidth = res.vehicles.overall_dimension
              ? Number(res.vehicles.overall_dimension.split("X")[1]) / 1000
              : "";
            this.vehicleInfo.VehicleLoad = res.vehicles.approved_load
              ? Number(res.vehicles.approved_load.split("kg")[0]) / 1000
              : "";
            this.vehicleInfo.VehicleTotalWeight = res.vehicles.gross_mass
              ? Number(res.vehicles.gross_mass.split("kg")[0]) / 1000
              : "";
            this.vehicleInfo.VehicleLoadWeight = res.vehicles.unladen_mass
              ? Number(res.vehicles.unladen_mass.split("kg")[0]) / 1000
              : "";
            this.vehicleInfo.VehicleLoadPersons = res.vehicles
              .appproved_passenger_capacity
              ? res.vehicles.appproved_passenger_capacity
              : "";
            if (res.vehicles.energyType) {
              this.powerList.forEach((item) => {
                if (item.Name == res.vehicles.energyType) {
                  this.vehicleInfo.VehiclePowerType = item.Code;
                }
              });
            }
            if (res.vehicles.vehicleColor) {
              this.vehicleColorList.forEach((item) => {
                if (item.Name == res.vehicles.vehicleColor) {
                  this.vehicleInfo.CarNumberColor = item.Code;
                }
              });
            }

            //重型半挂牵引车 默认值
            if( this.vehicleInfo.VehicleTypeName=='重型半挂牵引车'){
                this.vehicleInfo.VehicleLoad=31.2;
                this.vehicleInfo.VehicleTotalWeight=8;
                this.vehicleInfo.VehicleLoadWeight=40;
                this.vehicleInfo.VehicleLength=13.5;
              }

          })
          .catch(() => {
            this.deleteImg(field);
          })
          .finally(() => {
            this.$refs.vehiclePageOn.dialog();
            field.loading = false;
            field.uploaded = false;
          });
      }else{
        this.uploadImg(field);
        this.$refs.vehiclePageOn.dialog();
      }
      
    },
    // 上传行驶证正面
    upVehicleFace(field) {
      field.loading = true;
      if(this.isOcrImg){
        let formData = new FormData();
        formData.append("file", this.currentFile);
        uploadVehicleFace(formData)
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            field.picUrl = res.vehicles.vehicleFaceUrl;
            this.vehicleInfo.VehicleCarNumber = this.vehicleInfo.VehicleCarNumber
              ? this.vehicleInfo.VehicleCarNumber
              : res.vehicles.plate_num || "";
            this.vehicleInfo.VehicleMaster = res.vehicles.owner
              ? res.vehicles.owner
              : "";
            this.vehicleInfo.VehicleUsage = res.vehicles.use_character
              ? res.vehicles.use_character
              : "";
            this.vehicleInfo.VehicleTypeName = res.vehicles.vehicle_type
              ? res.vehicles.vehicle_type
              : "";
            this.vehicleInfo.VehicleVIN = res.vehicles.vin
              ? res.vehicles.vin
              : "";
            this.vehicleInfo.PowerNumber = res.vehicles.engine_num
              ? res.vehicles.engine_num
              : "";
            this.vehicleInfo.BrandModelNumber = res.vehicles.model
              ? res.vehicles.model
              : "";
            this.vehicleInfo.VehicleLicenseRegDate = res.vehicles.register_date
              ? this.insertStr(res.vehicles.register_date, 4, "-", 7, "-")
              : "";
            this.vehicleInfo.VehicleLicenseAwardDate = res.vehicles.issue_date
              ? this.insertStr(res.vehicles.issue_date, 4, "-", 7, "-")
              : "";
            this.vehicleInfo.VehicleLicesenAuthority = res.vehicles.Authority
              ? res.vehicles.Authority
              : "";

              //重型半挂牵引车 默认值
              if( this.vehicleInfo.VehicleTypeName=='重型半挂牵引车'){
                this.vehicleInfo.VehicleLoad=31.2;
                this.vehicleInfo.VehicleTotalWeight=8;
                this.vehicleInfo.VehicleLoadWeight=40;
                this.vehicleInfo.VehicleLength=13.5;
              }
          })
          .catch(() => {
            this.deleteImg(field);
          })
          .finally(() => {
            this.$refs.vehicleFace.dialog();
            field.loading = false;
            field.uploaded = false;
          });
      }else{
        this.uploadImg(field);
        this.$refs.vehicleFace.dialog();
      }
      
    },
    //识别日期插入
    insertStr(soure, firstPosition, firstStr, secondPosition, secondStr) {
      soure =
        soure.slice(0, firstPosition) + firstStr + soure.slice(firstPosition);
      return (
        soure.slice(0, secondPosition) + secondStr + soure.slice(secondPosition)
      );
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },
    //删除图片
    deleteImg(field) {
      field.uploaded = false;
      field.loading = false;
      field.picUrl = "";
    },
    // 获取车辆信息
    getVehicleDeatils(id) {
      VehicleDeatils({ id }).then((res) => {
        this.vehicleInfo = res.vehicles[0];
        if (res.vehicles[0].VehiclePowerType == "") {
          if (res.vehicles[0].VehicleCarNumber.length == 7) {
            if (res.vehicles[0].VehicleLoadWeight >= 4.5) {
              this.vehicleInfo.VehiclePowerType = "B";
            } else {
              this.vehicleInfo.VehiclePowerType = "A";
            }
          }
          if (res.vehicles[0].VehicleCarNumber.length == 8) {
            this.vehicleInfo.VehiclePowerType = "C";
          }
        }
        this.vehicleImg.vehicleFace.picUrl =
          res.vehicles[0].VehicleLicenseFrontPageURL;
        this.vehicleImg.vehicleBack.picUrl =
          res.vehicles[0].VehicleLicenseSubPageBackURL;
        this.vehicleImg.vehiclePageOn.picUrl =
          res.vehicles[0].VehicleLicenseSubPageOnURL;
        this.vehicleImg.vehicleRTP.picUrl = res.vehicles[0].VehicleRTPUTL;
        this.vehicleImg.elsePapers.picUrl =
          res.vehicles[0].AdditionalLicenseURL;
        // 用于大图展示
        if (this.vehicleImg.vehicleFace.picUrl) {
          this.srcList.push(this.vehicleImg.vehicleFace.picUrl);
        }
        if (this.vehicleImg.vehiclePageOn.picUrl) {
          this.srcList.push(this.vehicleImg.vehiclePageOn.picUrl);
        }
        if (this.vehicleImg.vehicleBack.picUrl) {
          this.srcList.push(this.vehicleImg.vehicleBack.picUrl);
        }
        if (this.vehicleImg.vehicleRTP.picUrl) {
          this.srcList.push(this.vehicleImg.vehicleRTP.picUrl);
        }
        if (this.vehicleImg.elsePapers.picUrl) {
          this.srcList.push(this.vehicleImg.elsePapers.picUrl);
        }
        if (res.vehicles[0].Status == 1 || res.vehicles[0].Status == 2) {
          this.OperatorName = res.VerifyName;
        }
        this.GetAuthorityByCarNumber();
      });
    },
    //获取快捷回复列表
    getFastReplyList() {
      let params = {
        type: 101,
      };
      getDataDict(params).then((res) => {
        this.fastReplyList = res.patterSetInfo;
      });
    },
    // 分割字符换行显示
    splitRejectReason(str) {
      if (str && str.includes(";")) {
        let strList = str.split(";");
        let res = "";
        strList.forEach((item) => {
          res += `
          <p style="margin: 5px 0;">${item}</p>
        `;
        });
        return res;
      } else {
        return str;
      }
    },

    GetAuthorityByCarNumber() {
      if (
        this.vehicleInfo.VehicleCarNumber &&
        !this.vehicleInfo.VehicleLicesenAuthority
      ) {
        GetAuthorityByCarNumber({
          carnumber: this.vehicleInfo.VehicleCarNumber,
        }).then((res) => {
          this.vehicleInfo.VehicleLicesenAuthority = res.data;
        });
      }
    },
     //自动审核失败批量处理
     BatchBackDraft(){
      let data=
      {
        Remark:'',
        Details:[]
      }
     
      let params = {
          CarNumber: this.vehicleInfo.VehicleCarNumber,
          AscriptionUserID: this.vehicleInfo.AscriptionUserID,
        };
      data.Details.push(params);

      this.$confirm("确定退回该车辆的审核信息吗","提示",{
        confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
      }).then(()=>{
          BatchDraftCar(data).then((res)=>{
          this.$message.success("操作成功!");
          this.routerBack();
        })
      })
    },
  },
  created() {
    this.setTCCarAutoCheckList();
    this.OperatorName = localStorage.getItem("carOperatorName")
      ? localStorage.getItem("carOperatorName")
      : "";
    // 编辑类型
    this.vehicleID = this.$route.query.VehicleID;
    this.vehicleInfo.vehicleID = this.$route.query.VehicleID;
    this.AscriptionUserID = this.$route.query.AscriptionUserID;
    this.urlType = this.$route.query.urlType;
    this.pageIndex = this.$route.query.pageIndex
      ? this.$route.query.pageIndex
      : 1;
    this.EnterpriseFullName = this.$route.query.EnterpriseFullName;
    this.LegalPersonlPhone = this.$route.query.LegalPersonlPhone;
    this.SubmitDatetimeStart = this.$route.query.SubmitDatetimeStart;
    this.SubmitDatetimeEnd = this.$route.query.SubmitDatetimeEnd;
    this.CarNumber = this.$route.query.CarNumber;
    this.auditStatus = this.$route.query.auditStatus;
    // 获取车辆信息
    this.getVehicleDeatils(this.$route.query.VehicleID);
    // 车牌颜色
    getDataDict({ type: 3 }).then((res) => {
      this.vehicleColorList = res.patterSetInfo;
    });
    // 能源类型
    getDataDict({ type: 10 }).then((res) => {
      this.powerList = res.patterSetInfo;
    });
    // 归属类型
    getDataDict({ type: 15 }).then((res) => {
      this.affiliation = res.patterSetInfo;
    });
    setTimeout(() => {
      if (
        this.vehicleInfo.VehicleCarNumber &&
        !this.vehicleInfo.VehicleLicesenAuthority
      ) {
        GetAuthorityByCarNumber({
          carnumber: this.vehicleInfo.VehicleCarNumber,
        }).then((res) => {
          this.vehicleInfo.VehicleLicesenAuthority = res.data;
        });
      }
    }, 2000);
    this.getFastReplyList();
  },
  components: {
    UploadImg,
    TXmap,
  },
};
</script>

<style lang="scss">
.addPageVehicle {
  .el-input__inner {
    width: 220px;
  }

  .el-form-item__content {
    width: 220px;
  }

  .el-input.is-disabled .el-input__inner {
    color: #000;
  }

  .el-textarea.is-disabled .el-textarea__inner {
    color: #000;
    background-color: #fff;
  }
}
</style>

<style lang="scss" scoped>
@import "../../../../assets/style/variable.scss";

.addPageVehicle {
  .topWrap {
    position: fixed;
    right: 50px;
    display: flex;
    justify-content: space-between;
    z-index: 999;

    .el-button {
      vertical-align: top;
      margin-top: -10px;
      margin-left: 30px;
    }
  }

  .head {
    display: flex;
    justify-content: center;
    // padding-bottom: 10px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .upBox {
      text-align: center;

      .upText {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0;
        color: #333333;
      }
    }

    .el-upload__tip {
      color: $textGray2;
    }
  }

  .title {
    color: $primary;
    position: relative;
    text-indent: 15px;
    font-size: 14px;
    border-bottom: 1px solid $borderGray;
    padding: 20px 0;
    margin-bottom: 20px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 14px;
      border-radius: 5px;
      background: $primary;
    }
  }
}

.img {
  width: 278px;
  height: 174px;
  border-radius: 10px;
}
</style>

<style>
/* 复选框样式 */
.rejectDialog .el-checkbox {
  display: block !important;
}
</style>
